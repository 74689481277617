var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hold-transition"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-md-12"},[_c('table',{staticClass:"table table-bordered table-striped table-hover table-sm text-xs"},[_vm._m(0),_c('tbody',{attrs:{"id":"tbody"}},[_vm._l((_vm.tipoServSolicitudes),function(detTipoServ,index){return _c('tr',{key:detTipoServ.id},[_c('td',{staticClass:"text-center"},[(_vm.estado_det_solicitud != 2)?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":_vm.estado_det_solicitud != -1},on:{"click":function($event){return _vm.editTipoServSolicitud(index)}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e(),(_vm.estado_det_solicitud == 2 && _vm.id_actualizar_det == index)?_c('div',{staticClass:"btn-group"},[(!_vm.$v.tipoServSolicitud.$invalid)?_c('button',{staticClass:"btn bg-success",attrs:{"type":"button"},on:{"click":function($event){return _vm.saveUpdateTipoServSolicitud(index)}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e(),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelTipoServSolicitud()}}},[_c('i',{staticClass:"fas fa-trash"})])]):_vm._e(),(
                  _vm.estado_det_solicitud == 2 && _vm.id_actualizar_det !== index
                )?_c('div',{staticClass:"btn-group"},[_c('button',{staticClass:"btn bg-navy",attrs:{"type":"button","disabled":""},on:{"click":function($event){return _vm.editTipoServSolicitud(index)}}},[_c('i',{staticClass:"fas fa-edit"})])]):_vm._e()]),_c('td',{staticClass:"text-center"},[(_vm.estado_det_solicitud == 2 && _vm.id_actualizar_det == index)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_servicio_obj),expression:"tipoServSolicitud.tipo_servicio_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_servicio_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"id":"tipo_servicio","disabled":""},on:{"input":function($event){return _vm.changeTipoVehi()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_servicio_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_servicios),function(tipo_servicio){return _c('option',{key:tipo_servicio.numeracion,domProps:{"value":tipo_servicio}},[_vm._v(" "+_vm._s(tipo_servicio.descripcion)+" ")])})],2)]):_c('div',[(
                    detTipoServ.tipo_servicio_obj !== undefined &&
                      detTipoServ.tipo_vh_equi_obj !== null
                  )?_c('div',[_vm._v(" "+_vm._s(detTipoServ.tipo_servicio_obj.descripcion)+" ")]):_c('div',[_vm._v(" "+_vm._s(detTipoServ.tipoServicio)+" ")])])]),_c('td',[(_vm.estado_det_solicitud == 2 && _vm.id_actualizar_det == index)?_c('div',[(_vm.tipoServSolicitud.tipo_servicio_obj.numeracion == 1)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_vh_equi_obj),expression:"tipoServSolicitud.tipo_vh_equi_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"id":"tipo_vh_equi","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_vh_equi_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_vehiculos),function(tipo_vehiculo){return _c('option',{key:tipo_vehiculo.id,domProps:{"value":tipo_vehiculo}},[_vm._v(" "+_vm._s(tipo_vehiculo.nombre)+" ")])})],2)]):_vm._e(),(_vm.tipoServSolicitud.tipo_servicio_obj.numeracion == 2)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_vh_equi_obj),expression:"tipoServSolicitud.tipo_vh_equi_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"id":"tipo_vh_equi","disabled":""},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_vh_equi_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_equipos),function(tipo_equipo){return _c('option',{key:tipo_equipo.numeracion,domProps:{"value":tipo_equipo}},[_vm._v(" "+_vm._s(tipo_equipo.descripcion)+" ")])})],2)]):_vm._e()]):_c('div',[(
                    detTipoServ.tipo_vh_equi_obj !== undefined &&
                      detTipoServ.tipo_vh_equi_obj !== null
                  )?_c('div',[(detTipoServ.tipo_servicio == 1)?_c('div',[_vm._v(" "+_vm._s(detTipoServ.tipo_vh_equi_obj.nombre)+" ")]):_c('div',[_vm._v(" "+_vm._s(detTipoServ.tipo_vh_equi_obj.descripcion)+" ")])]):_c('div',[(detTipoServ.tipo_servicio == 1)?_c('div',[_vm._v(" "+_vm._s(detTipoServ.tipoVh)+" ")]):_c('div',[_vm._v(" "+_vm._s(detTipoServ.tipoEquipo)+" ")])])])]),_c('td',{staticClass:"text-center"},[(_vm.estado_det_solicitud == 2 && _vm.id_actualizar_det == index)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.cantidad),expression:"tipoServSolicitud.cantidad"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.cantidad.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"number","id":"cantidad_vehiculos","disabled":""},domProps:{"value":(_vm.tipoServSolicitud.cantidad)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tipoServSolicitud, "cantidad", $event.target.value)}}})]):_c('div',[_vm._v(" "+_vm._s(detTipoServ.cantidad)+" ")])]),_c('td',{staticClass:"text-center"},[(_vm.estado_det_solicitud == 2 && _vm.id_actualizar_det == index)?_c('div',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tiempo),expression:"tipoServSolicitud.tiempo"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tiempo.$invalid
                      ? 'is-invalid'
                      : 'is-valid',attrs:{"type":"text","id":"tiempo","disabled":""},domProps:{"value":(_vm.tipoServSolicitud.tiempo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tipoServSolicitud, "tiempo", $event.target.value)}}})]):_c('div',[_vm._v(_vm._s(detTipoServ.tiempo))])]),_c('td',{staticClass:"text-center",class:detTipoServ.empresa_id !== null
                  ? 'table-success'
                  : 'table-warning'},[(_vm.estado_det_solicitud == 2 && _vm.id_actualizar_det == index)?_c('div',[_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                    _vm.$v.tipoServSolicitud.empresa_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                  ],attrs:{"placeholder":"Empresa","label":"razon_social","options":_vm.listasForms.empresas,"filterable":true},on:{"input":function($event){return _vm.selectEmpresa()}},model:{value:(_vm.tipoServSolicitud.empresa),callback:function ($$v) {_vm.$set(_vm.tipoServSolicitud, "empresa", $$v)},expression:"tipoServSolicitud.empresa"}})],1):_c('div',[(
                    detTipoServ.empresa !== null &&
                      detTipoServ.empresa !== undefined
                  )?_c('div',[_vm._v(" "+_vm._s(detTipoServ.empresa.razon_social)+" ")]):_c('div')])])])}),(_vm.estado_det_solicitud == 1)?_c('tr',[_c('td',[_c('div',{staticClass:"btn-group float-right"},[(!_vm.$v.tipoServSolicitud.$invalid)?_c('button',{staticClass:"btn bg-success",on:{"click":function($event){return _vm.saveTipoServSolicitud()}}},[_c('i',{staticClass:"fas fa-save"})]):_vm._e(),_c('button',{staticClass:"btn bg-danger",on:{"click":function($event){return _vm.cancelTipoServSolicitud()}}},[_c('i',{staticClass:"fas fa-trash"})])])]),_c('td',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_servicio_obj),expression:"tipoServSolicitud.tipo_servicio_obj"}],staticClass:"form-control form-control-sm col-md-12",class:_vm.$v.tipoServSolicitud.tipo_servicio_obj.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"id":"tipo_servicio"},on:{"input":function($event){return _vm.changeTipoVehi()},"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_servicio_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_servicios),function(tipo_servicio){return _c('option',{key:tipo_servicio.numeracion,domProps:{"value":tipo_servicio}},[_vm._v(" "+_vm._s(tipo_servicio.descripcion)+" ")])})],2)]),_c('td',[(_vm.tipoServSolicitud.tipo_servicio_obj)?_c('div',[(_vm.tipoServSolicitud.tipo_servicio_obj.numeracion == 1)?_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_vh_equi_obj),expression:"tipoServSolicitud.tipo_vh_equi_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"id":"tipo_vh_equi"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_vh_equi_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_vehiculos),function(tipo_vehiculo){return _c('option',{key:tipo_vehiculo.id,domProps:{"value":tipo_vehiculo}},[_vm._v(" "+_vm._s(tipo_vehiculo.nombre)+" ")])})],2)]):_c('div',[_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tipo_vh_equi_obj),expression:"tipoServSolicitud.tipo_vh_equi_obj"}],staticClass:"form-control form-control-sm",class:_vm.$v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid',attrs:{"id":"tipo_vh_equi"},on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.$set(_vm.tipoServSolicitud, "tipo_vh_equi_obj", $event.target.multiple ? $$selectedVal : $$selectedVal[0])}}},[_c('option',{attrs:{"value":""}},[_vm._v("Seleccione...")]),_vm._l((_vm.listasForms.tipos_equipos),function(tipo_equipo){return _c('option',{key:tipo_equipo.numeracion,domProps:{"value":tipo_equipo}},[_vm._v(" "+_vm._s(tipo_equipo.descripcion)+" ")])})],2)])]):_vm._e()]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.cantidad),expression:"tipoServSolicitud.cantidad"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.tipoServSolicitud.cantidad.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"number","id":"cantidad_vehiculos"},domProps:{"value":(_vm.tipoServSolicitud.cantidad)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tipoServSolicitud, "cantidad", $event.target.value)}}})]),_c('td',[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.tipoServSolicitud.tiempo),expression:"tipoServSolicitud.tiempo"}],staticClass:"form-control form-control-sm p-2",class:_vm.$v.tipoServSolicitud.tiempo.$invalid
                    ? 'is-invalid'
                    : 'is-valid',attrs:{"type":"number","id":"tiempo"},domProps:{"value":(_vm.tipoServSolicitud.tiempo)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.tipoServSolicitud, "tiempo", $event.target.value)}}})]),_c('td',[_c('v-select',{staticClass:"form-control form-control-sm p-0",class:[
                  _vm.$v.tipoServSolicitud.empresa_id.$invalid
                    ? 'is-invalid'
                    : 'is-valid',
                  _vm.$store.getters.getDarkMode ? 'dark-vselect' : '',
                ],attrs:{"placeholder":"Empresa","label":"razon_social","options":_vm.listasForms.empresas,"filterable":true},on:{"input":function($event){return _vm.selectEmpresa()}},model:{value:(_vm.tipoServSolicitud.empresa),callback:function ($$v) {_vm.$set(_vm.tipoServSolicitud, "empresa", $$v)},expression:"tipoServSolicitud.empresa"}})],1)]):_vm._e()],2)]),_c('div',{staticClass:"col-md-12 text-center"},[(
            _vm.tipoServSolicitudes.some(
              (tipoVehi) => tipoVehi.empresa_id == null
            )
          )?_c('h6',[_vm._m(1)]):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',{staticClass:"bg-gray text-center"},[_c('tr',[_c('th',{staticClass:"text-nowrap"},[_vm._v(" Acciones ")]),_c('th',{staticStyle:{"width":"10%"}},[_vm._v("Tipo Servicio")]),_c('th',[_vm._v("Vehículo/Equipo")]),_c('th',[_vm._v("Cantidad Vehículos a Solicitar")]),_c('th',[_vm._v("Tiempo Requerido del Servicio (Dias)")]),_c('th',{staticClass:"text-center"},[_vm._v("Empresa")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"badge badge-warning"},[_c('i',{staticClass:"fas fa-times-circle"}),_vm._v(" Por Favor asignar la empresa a la solicitud ")])
}]

export { render, staticRenderFns }