<template>
  <div class="hold-transition">
    <div class="row">
      <div class="col-md-12">
        <table
          class="
            table table-bordered table-striped table-hover table-sm
            text-xs
          "
        >
          <thead class="bg-gray text-center">
            <tr>
              <th class="text-nowrap">
                Acciones
                <!-- <button
                  type="button"
                  class="btn btn-sm bg-primary text-white"
                  @click="addTipoServSolicitud()"
                  :disabled="
                    estado_det_solicitud == 1 ||
                    !$parent.form.id ||
                    $parent.form.estado == 2
                  "
                  style="cursor: pointer"
                >
                  <i class="fas fa-plus"></i>
                </button> -->
              </th>
              <th style="width: 10%">Tipo Servicio</th>
              <th>Vehículo/Equipo</th>
              <th>Cantidad Vehículos a Solicitar</th>
              <th>Tiempo Requerido del Servicio (Dias)</th>
              <th class="text-center">Empresa</th>
            </tr>
          </thead>
          <tbody id="tbody">
            <tr
              v-for="(detTipoServ, index) in tipoServSolicitudes"
              :key="detTipoServ.id"
            >
              <td class="text-center">
                <div class="btn-group" v-if="estado_det_solicitud != 2">
                  <button
                    type="button"
                    class="btn bg-navy"
                    :disabled="estado_det_solicitud != -1"
                    @click="editTipoServSolicitud(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <!-- <button
                    class="btn bg-danger"
                    :disabled="estado_det_solicitud != -1"
                    @click="destroytTipoServSolicitud(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button> -->
                </div>
                <div
                  class="btn-group"
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <button
                    type="button"
                    class="btn bg-success"
                    v-if="!$v.tipoServSolicitud.$invalid"
                    @click="saveUpdateTipoServSolicitud(index)"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button
                    class="btn bg-danger"
                    @click="cancelTipoServSolicitud()"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
                <div
                  class="btn-group"
                  v-if="
                    estado_det_solicitud == 2 && id_actualizar_det !== index
                  "
                >
                  <button
                    type="button"
                    class="btn bg-navy"
                    disabled
                    @click="editTipoServSolicitud(index)"
                  >
                    <i class="fas fa-edit"></i>
                  </button>
                  <!-- <button
                    class="btn bg-danger"
                    disabled
                    @click="destroytTipoServSolicitud(index)"
                  >
                    <i class="fas fa-trash"></i>
                  </button> -->
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <select
                    id="tipo_servicio"
                    class="form-control form-control-sm"
                    v-model="tipoServSolicitud.tipo_servicio_obj"
                    :class="
                      $v.tipoServSolicitud.tipo_servicio_obj.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    @input="changeTipoVehi()"
                    disabled
                  >
                    <option value="">Seleccione...</option>
                    <option
                      v-for="tipo_servicio in listasForms.tipos_servicios"
                      :key="tipo_servicio.numeracion"
                      :value="tipo_servicio"
                    >
                      {{ tipo_servicio.descripcion }}
                    </option>
                  </select>
                </div>
                <div v-else>
                  <div
                    v-if="
                      detTipoServ.tipo_servicio_obj !== undefined &&
                        detTipoServ.tipo_vh_equi_obj !== null
                    "
                  >
                    {{ detTipoServ.tipo_servicio_obj.descripcion }}
                  </div>
                  <div v-else>
                    {{ detTipoServ.tipoServicio }}
                  </div>
                </div>
              </td>
              <td>
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <div
                    v-if="tipoServSolicitud.tipo_servicio_obj.numeracion == 1"
                  >
                    <select
                      id="tipo_vh_equi"
                      class="form-control form-control-sm"
                      v-model="tipoServSolicitud.tipo_vh_equi_obj"
                      :class="
                        $v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      disabled
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_vehiculo in listasForms.tipos_vehiculos"
                        :key="tipo_vehiculo.id"
                        :value="tipo_vehiculo"
                      >
                        {{ tipo_vehiculo.nombre }}
                      </option>
                    </select>
                  </div>
                  <div
                    v-if="tipoServSolicitud.tipo_servicio_obj.numeracion == 2"
                  >
                    <select
                      id="tipo_vh_equi"
                      class="form-control form-control-sm"
                      v-model="tipoServSolicitud.tipo_vh_equi_obj"
                      :class="
                        $v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                      disabled
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_equipo in listasForms.tipos_equipos"
                        :key="tipo_equipo.numeracion"
                        :value="tipo_equipo"
                      >
                        {{ tipo_equipo.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-if="
                      detTipoServ.tipo_vh_equi_obj !== undefined &&
                        detTipoServ.tipo_vh_equi_obj !== null
                    "
                  >
                    <div v-if="detTipoServ.tipo_servicio == 1">
                      {{ detTipoServ.tipo_vh_equi_obj.nombre }}
                    </div>
                    <div v-else>
                      {{ detTipoServ.tipo_vh_equi_obj.descripcion }}
                    </div>
                  </div>
                  <div v-else>
                    <div v-if="detTipoServ.tipo_servicio == 1">
                      {{ detTipoServ.tipoVh }}
                    </div>
                    <div v-else>
                      {{ detTipoServ.tipoEquipo }}
                    </div>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="cantidad_vehiculos"
                    v-model="tipoServSolicitud.cantidad"
                    :class="
                      $v.tipoServSolicitud.cantidad.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                  />
                </div>
                <div v-else>
                  {{ detTipoServ.cantidad }}
                </div>
              </td>
              <td class="text-center">
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="tiempo"
                    v-model="tipoServSolicitud.tiempo"
                    :class="
                      $v.tipoServSolicitud.tiempo.$invalid
                        ? 'is-invalid'
                        : 'is-valid'
                    "
                    disabled
                  />
                </div>
                <div v-else>{{ detTipoServ.tiempo }}</div>
              </td>
              <td
                class="text-center"
                :class="
                  detTipoServ.empresa_id !== null
                    ? 'table-success'
                    : 'table-warning'
                "
              >
                <div
                  v-if="estado_det_solicitud == 2 && id_actualizar_det == index"
                >
                  <v-select
                    :class="[
                      $v.tipoServSolicitud.empresa_id.$invalid
                        ? 'is-invalid'
                        : 'is-valid',
                      $store.getters.getDarkMode ? 'dark-vselect' : '',
                    ]"
                    v-model="tipoServSolicitud.empresa"
                    placeholder="Empresa"
                    label="razon_social"
                    class="form-control form-control-sm p-0"
                    :options="listasForms.empresas"
                    :filterable="true"
                    @input="selectEmpresa()"
                  ></v-select>
                </div>
                <div v-else>
                  <div
                    v-if="
                      detTipoServ.empresa !== null &&
                        detTipoServ.empresa !== undefined
                    "
                  >
                    {{ detTipoServ.empresa.razon_social }}
                  </div>
                  <div v-else></div>
                </div>
              </td>
            </tr>
            <tr v-if="estado_det_solicitud == 1">
              <td>
                <div class="btn-group float-right">
                  <button
                    class="btn bg-success"
                    v-if="!$v.tipoServSolicitud.$invalid"
                    @click="saveTipoServSolicitud()"
                  >
                    <i class="fas fa-save"></i>
                  </button>
                  <button
                    class="btn bg-danger"
                    @click="cancelTipoServSolicitud()"
                  >
                    <i class="fas fa-trash"></i>
                  </button>
                </div>
              </td>
              <td>
                <select
                  id="tipo_servicio"
                  class="form-control form-control-sm col-md-12"
                  v-model="tipoServSolicitud.tipo_servicio_obj"
                  :class="
                    $v.tipoServSolicitud.tipo_servicio_obj.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @input="changeTipoVehi()"
                >
                  <option value="">Seleccione...</option>
                  <option
                    v-for="tipo_servicio in listasForms.tipos_servicios"
                    :key="tipo_servicio.numeracion"
                    :value="tipo_servicio"
                  >
                    {{ tipo_servicio.descripcion }}
                  </option>
                </select>
              </td>
              <td>
                <div v-if="tipoServSolicitud.tipo_servicio_obj">
                  <div
                    v-if="tipoServSolicitud.tipo_servicio_obj.numeracion == 1"
                  >
                    <select
                      id="tipo_vh_equi"
                      class="form-control form-control-sm"
                      v-model="tipoServSolicitud.tipo_vh_equi_obj"
                      :class="
                        $v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_vehiculo in listasForms.tipos_vehiculos"
                        :key="tipo_vehiculo.id"
                        :value="tipo_vehiculo"
                      >
                        {{ tipo_vehiculo.nombre }}
                      </option>
                    </select>
                  </div>
                  <div v-else>
                    <select
                      id="tipo_vh_equi"
                      class="form-control form-control-sm"
                      v-model="tipoServSolicitud.tipo_vh_equi_obj"
                      :class="
                        $v.tipoServSolicitud.tipo_vh_equi_obj.$invalid
                          ? 'is-invalid'
                          : 'is-valid'
                      "
                    >
                      <option value="">Seleccione...</option>
                      <option
                        v-for="tipo_equipo in listasForms.tipos_equipos"
                        :key="tipo_equipo.numeracion"
                        :value="tipo_equipo"
                      >
                        {{ tipo_equipo.descripcion }}
                      </option>
                    </select>
                  </div>
                </div>
              </td>
              <td>
                <input
                  type="number"
                  class="form-control form-control-sm p-2"
                  id="cantidad_vehiculos"
                  v-model="tipoServSolicitud.cantidad"
                  :class="
                    $v.tipoServSolicitud.cantidad.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </td>
              <td>
                <input
                  type="number"
                  class="form-control form-control-sm p-2"
                  id="tiempo"
                  v-model="tipoServSolicitud.tiempo"
                  :class="
                    $v.tipoServSolicitud.tiempo.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                />
              </td>
              <td>
                <v-select
                  :class="[
                    $v.tipoServSolicitud.empresa_id.$invalid
                      ? 'is-invalid'
                      : 'is-valid',
                    $store.getters.getDarkMode ? 'dark-vselect' : '',
                  ]"
                  v-model="tipoServSolicitud.empresa"
                  placeholder="Empresa"
                  label="razon_social"
                  class="form-control form-control-sm p-0"
                  :options="listasForms.empresas"
                  :filterable="true"
                  @input="selectEmpresa()"
                ></v-select>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="col-md-12 text-center">
          <h6
            v-if="
              tipoServSolicitudes.some(
                (tipoVehi) => tipoVehi.empresa_id == null
              )
            "
          >
            <span class="badge badge-warning">
              <i class="fas fa-times-circle"></i> Por Favor asignar la empresa a
              la solicitud
            </span>
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";
import axios from "axios";

export default {
  name: "CsAsignacionesTipoVehiculo",
  components: {
    vSelect,
  },

  data() {
    return {
      tipoServSolicitudes: [],
      tipoServSolicitud: {
        id: null,
        cs_solicitud_nacional_id: null,
        tipo_servicio_obj: null,
        tipo_servicio: null,
        tipo_vh_equi_obj: null,
        tipo_vh_equi: null,
        cantidad: null,
        tiempo: null,
        empresa_id: null,
        empresa: null,
      },
      listasForms: {
        tipos_servicios: [],
        tipos_vehiculos: [],
        tipos_equipos: [],
      },
      estado_det_solicitud: -1,
      id_actualizar_det: -1,
      detSolicitudAnt: null,
      flag_formulario: false,
    };
  },

  validations: {
    tipoServSolicitud: {
      tipo_servicio_obj: {
        required,
      },
      tipo_vh_equi_obj: {
        required,
      },
      cantidad: {
        required,
      },
      tiempo: {
        required,
      },
      empresa_id: {
        required,
      },
    },
  },

  methods: {
    async getTipoServSolicitudes() {
      this.cargando = true;
      await axios
        .get("/api/funcionariosFrontera/solicitudesNacionalesTipoServ", {
          params: { solicitudNacional_id: this.$parent.form.id },
        })
        .then(async (response) => {
          this.tipoServSolicitudes = response.data;
          await this.llenarTipoServSolicitud();
          this.cargando = false;
        });
    },

    selectEmpresa() {
      this.tipoServSolicitud.empresa_id = null;
      if (
        this.tipoServSolicitud.empresa !== null &&
        this.tipoServSolicitud.empresa !== undefined
      ) {
        this.tipoServSolicitud.empresa_id = this.tipoServSolicitud.empresa.id;
      }
    },

    async getEmpresas() {
      let me = this;
      var url = "api/admin/empresas/lista?estado=1";
      axios
        .get(url, {
          params: {
            linea_negocio_id: [4],
          },
        })
        .then(function(response) {
          me.listasForms.empresas = response.data;
        })
        .catch(function(e) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + e,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTipoServicio() {
      await axios.get("/api/lista/128").then((response) => {
        this.listasForms.tipos_servicios = response.data;
      });
    },

    async getTipoVehiculos() {
      let me = this;
      var url = "api/admin/tiposVehiculos/lista";
      axios
        .get(url, {
          params: {
            linea_negocio_id: 4,
          },
        })
        .then(function(response) {
          let respuesta = response.data;
          me.listasForms.tipos_vehiculos = respuesta;
        })
        .catch(function(error) {
          this.$swal({
            icon: "error",
            title: "Ocurrio un error" + error,
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000,
            timerProgressBar: true,
          });
        });
    },

    async getTipoEquipo() {
      await axios.get("/api/lista/129").then((response) => {
        this.listasForms.tipos_equipos = response.data;
      });
    },

    changeTipoVehi() {
      this.tipoServSolicitud.tipo_vh_equi_obj = null;
      this.tipoServSolicitud.tipo_vh_equi = null;
    },

    async llenarTipoServSolicitud() {
      await this.tipoServSolicitudes.forEach(async (element) => {
        element.estado = 1;
        element.tipo_servicio_obj = await this.listasForms.tipos_servicios.filter(
          (item) => {
            if (item.numeracion == element.tipo_servicio) {
              return item;
            }
          }
        )[0];

        if (element.tipo_servicio == 1) {
          element.tipo_vh_equi_obj = this.listasForms.tipos_vehiculos.find(
            (item) => item.id === element.tipo_vh_equi
          );
        }

        if (element.tipo_servicio == 2) {
          element.tipo_vh_equi_obj = this.listasForms.tipos_equipos.find(
            (item) => item.numeracion === element.tipo_vh_equi
          );
        }
      });
      this.validaTipoServicio();
    },

    async addTipoServSolicitud() {
      await this.limpiarTipoServSolicitud();
      this.tipoServSolicitud.cs_solicitud_nacional_id = this.$parent.form.id;
      this.estado_det_solicitud = 1;
    },

    async cancelTipoServSolicitud() {
      this.estado_det_solicitud = -1;
    },

    async saveTipoServSolicitud() {
      let me = this;
      if (me.tipoServSolicitud.tipo_servicio_obj.numeracion == 1) {
        me.tipoServSolicitud.tipo_vh_equi =
          me.tipoServSolicitud.tipo_vh_equi_obj.id;
      } else {
        me.tipoServSolicitud.tipo_vh_equi =
          me.tipoServSolicitud.tipo_vh_equi_obj.numeracion;
      }
      me.tipoServSolicitud.tipo_servicio =
        me.tipoServSolicitud.tipo_servicio_obj.numeracion;
      me.tipoServSolicitudes.push(me.tipoServSolicitud);
      me.estado_det_solicitud = -1;
    },

    async saveTipoServSolicitudTotal() {
      this.cargando = true;
      axios({
        method: "POST",
        url: "/api/funcionariosFrontera/solicitudesNacionalesTipoServ",
        data: {
          cs_solicitud_nacional_id: this.$parent.form.id,
          tipoServSolicitudes: this.tipoServSolicitudes,
        },
      })
        .then(async (response) => {
          this.cargando = false;
          await this.getTipoServSolicitudes;
        })
        .catch((e) => {
          this.message += e.message;
        });
    },

    async editTipoServSolicitud(index) {
      this.id_actualizar_det = index;
      this.estado_det_solicitud = 2;
      this.tipoServSolicitud = { ...this.tipoServSolicitudes[index] };
    },

    async saveUpdateTipoServSolicitud(index) {
      if (this.tipoServSolicitud.tipo_servicio_obj.numeracion == 1) {
        this.tipoServSolicitud.tipo_vh_equi = this.tipoServSolicitud.tipo_vh_equi_obj.id;
      } else {
        this.tipoServSolicitud.tipo_vh_equi = this.tipoServSolicitud.tipo_vh_equi_obj.numeracion;
      }
      this.tipoServSolicitud.tipo_servicio = this.tipoServSolicitud.tipo_servicio_obj.numeracion;
      this.tipoServSolicitudes[index] = this.tipoServSolicitud;
      this.estado_det_solicitud = -1;
      this.id_actualizar_det = -1;
      this.validaTipoServicio();
    },

    validaTipoServicio() {
      this.flag_formulario = true;
      this.tipoServSolicitudes.forEach((ts) => {
        if (!ts.empresa_id) {
          this.flag_formulario = false;
        }
      });
    },

    async destroytTipoServSolicitud(index) {
      this.tipoServSolicitudes.splice(index, 1);
    },

    async limpiarTipoServSolicitud() {
      this.tipoServSolicitud = {
        id: null,
        cs_solicitud_nacional_id: null,
        tipo_servicio: null,
        tipo_servicio_obj: null,
        tipo_vh_equi: null,
        tipo_vh_equi_obj: null,
        cantidad: null,
        tiempo: null,
        empresa_id: null,
        empresa: null,
      };
    },
  },

  async mounted() {
    await this.getTipoVehiculos();
    await this.getTipoEquipo();
    await this.getTipoServicio();
    await this.getEmpresas();
  },
};
</script>
